import {
  numberToCurrency,
  humanizedDate,
  inputDate,
} from "packs/lib/formateable";

export const identifyProduct = (productType) => {
  let kindOfProduct = "";
  switch (productType) {
    case "house":
      kindOfProduct = "Casa";
      break;
    case "apartment":
      kindOfProduct = "Depto";
      break;
    case "cellar":
      kindOfProduct = "Bodega";
      break;
    case "parking_lot":
      kindOfProduct = "Estacionamiento";
      break;
    case "extension":
      kindOfProduct = "Ampliación";
      break;
    default:
      break;
  }
  return kindOfProduct;
};

export const productTableRow = (product) => {
  return `
    <tr id="product${product.id}">
      <td>${product.category.translation} - ${product.name || ""}</td>
      <td>${product.line || ""}</td>
      <td>${product.orientation || ""}</td>
      <td>${numberToCurrency(
        product.product_price.list_price.toFixed(2) || 0
      )}</td>
      <td>
        <button type="button" class="btn-small btn-flat btn-product-table" data-product="${
          product.id
        }"><span class="material-icons btn-product-table" data-product="${
    product.id
  }">delete</span></button>
      </td>
    </tr>
  `;
};

const productInfoToDisplay = (product) => {};

const productNVP = (product) => {
  return `
    <div class="row">
      <div class="col s12 m3">
        <div class="field center">
          <label class="active">VNA 1</label>
          <input readonly type="text" class="no-border center fs-12 product-nvp-price-${
            product.id
          }-1" value="${numberToCurrency(
    product.product_price.list_price.toFixed(2)
  )}" name="quotated_products_attributes[][calc_from_default_payment]" />
        </div>
      </div>
      <div class="col s12 m3">
        <div class="field center">
          <label class="active">VNA 2</label>
          <input readonly type="text" class="no-border center fs-12 product-nvp-price-${
            product.id
          }-2" value="${numberToCurrency(
    product.product_price.list_price.toFixed(2)
  )}" name="quotated_products_attributes[][calc_after_default_payment]" />
        </div>
      </div>
      <div class="col s12 m3">
        <div class="field center">
          <label class="active">Saldo</label>
          <input readonly type="text" class="no-border center fs-12 product-total-installment-remain-price-${
            product.id
          }" value="0" name="quotated_products_attributes[][calc_remaining]" />
        </div>
      </div>
      <div class="col s12 m3">
        <div class="field center">
          <label class="active">Precio proyectado</label>
          <input readonly type="text" class="no-border center fs-12 product-total-installment-price-${
            product.id
          }" value="${numberToCurrency(
    product.product_price.list_price.toFixed(2)
  )}" name="quotated_products_attributes[][calc_sum_installments]" />
        </div>
      </div>
    </div>
  `;
};

const productNVP2 = (product) => {
  return `
    <div class="row">
      <div class="col s12 m3">
        <div class="field center">
          <label class="active">VNA 1</label>
          <input readonly type="text" class="no-border center fs-12 product-nvp-price-${
            product.id
          }-1" value="${numberToCurrency(
    product.product_price.list_price.toFixed(2)
  )}"/>
        </div>
      </div>
      <div class="col s12 m3">
        <div class="field center">
          <label class="active">VNA 2</label>
          <input readonly type="text" class="no-border center fs-12 product-nvp-price-${
            product.id
          }-2" value="${numberToCurrency(
    product.product_price.list_price.toFixed(2)
  )}"/>
        </div>
      </div>
      <div class="col s12 m3">
        <div class="field center">
          <label class="active">Saldo</label>
          <input readonly type="text" class="no-border center fs-12 product-total-installment-remain-price-${
            product.id
          }" value="0"/>
        </div>
      </div>
      <div class="col s12 m3">
        <div class="field center">
          <label class="active">Precio proyectado</label>
          <input readonly type="text" class="no-border center fs-12 product-total-installment-price-${
            product.id
          }" value="${numberToCurrency(
    product.product_price.list_price.toFixed(2)
  )}"/>
        </div>
      </div>
    </div>
  `;
};

const productPaymentContent = (product) => {
  return `
    <div class="row product-form-${product.id}">
      <div class="col s12">
        <div class="row">
          <div class="col s12 ${
            product.kind_of_product == "principal" ? "m4" : "m12"
          }">
            <div class="field center">
              <input readonly name="quotated_products_attributes[][product_id]" type="hidden" value="${
                product.id
              }" />
              <label class="active">Precio lista</label>
              <input readonly class="no-border center product-base-price-${
                product.id
              } product-hook hook-${
    product.id
  }" data-action="change-base-value" data-product="${
    product.id
  }" name="quotated_products_attributes[][base_tooked_price]" step="0.1" type="number" value="${product.product_price.list_price.toFixed(
    2
  )}" />
            </div>
          </div>
          <div class="col s12 m4 ${
            product.kind_of_product == "principal" ? "" : "hide"
          }">
            <div class="field">
              <label class="active">Descuento</label>
              <input class="mwp-90 product-discount-${
                product.id
              } product-hook hook-${
    product.id
  }" data-action="change-discount" data-product="${
    product.id
  }" max="5.5" min="0" name="quotated_products_attributes[][applied_discount]" step="0.01" type="number" value="0" />
              <span class="mwp-10">%</span>
            </div>
          </div>
          <div class="col s12 m4 ${
            product.kind_of_product == "principal" ? "" : "hide"
          }">
            <div class="field">
              <label class="active">Precio con descuento</label>
              <input type="number" class="product-sale-price-${
                product.id
              } product-hook hook-${
    product.id
  }" data-action="change-sale-price" data-product="${
    product.id
  }" min="0" name="quotated_products_attributes[][discounted_price]" step="0.01" value="${parseFloat(
    product.product_price.list_price
  ).toFixed(2)}" />
            </div>
          </div>
        </div>
        ${
          product.kind_of_product == "principal"
            ? installmentsProductPaymentContent(product)
            : ""
        }
      </div>
      ${
        product.kind_of_product == "principal"
          ? installmentsTableContent(product)
          : ""
      }
      ${
        product.kind_of_product == "principal"
          ? floatingNVPnumbers(product)
          : ""
      }
    </div>
  `;
};

const installmentsProductPaymentContent = (product) => {
  return `
    <div class="row hide">
      <div class="col s12">
        <div class="row">
          <div class="col s3 hide">
            <div class="field">
              <label class="active">Tipo valor pago durante la obra</label>
              <p>
                <label>
                  <input value="amount" class="foot-type-selector-for-${
                    product.id
                  }" name="quotated_products_attributes[][type-of-foot-${
    product.id
  }]" type="radio" checked data-product="${
    product.id
  }" data-foot-type="amount" />
                  <span>Monto ($)</span>
                </label>
              </p>
              <p>
                <label>
                  <input value="percent" class="foot-type-selector-for-${
                    product.id
                  }" name="quotated_products_attributes[][type-of-foot-${
    product.id
  }]" type="radio" data-product="${product.id}" data-foot-type="percent" />
                  <span>Porcentaje (%)</span>
                </label>
              </p>
            </div>
          </div>
          <div class="col s3 product-foot-percent-${product.id}-container">
            <div class="field">
              <label class="active">Pago durante la obra (%)</label>
              <div class="mt-10">
                <p class="range-field">
                  <input class="product-foot-percent-${
                    product.id
                  } product-hook hook-${
    product.id
  }" data-type-of-foot="percent" data-action="change-foot" data-product="${
    product.id
  }" max="100" min="0" name="quotated_products_attributes[][foot_percent]" step="0.01" type="range" value="${
    product.construction.project.minimum_foot_percent
  }" />
                </p>
              </div>
            </div>
          </div>
          <div class="col s3 product-foot-amount-${product.id}-container">
            <div class="field">
              <label class="active">Pago durante la obra ($)</label>
              <input class="product-foot-amount-${
                product.id
              } product-hook hook-${
    product.id
  }" data-type-of-foot="amount" data-action="change-foot" data-product="${
    product.id
  }" max="${product.product_price.list_price.toFixed(
    2
  )}" min="0" name="quotated_products_attributes[][foot_amount]" step="0.01" type="number" value="${
    product.product_price.list_price.toFixed(2) *
    (product.construction.project.minimum_foot_percent / 100)
  }" />
            </div>
          </div>
          <div class="col s6 product-installments-${product.id}-container hide">
            <div class="field">
              <label class="active">Cuotas</label>
              <input class="product-installments-${
                product.id
              } product-hook hook-${
    product.id
  }" data-action="change-installments" data-product="${product.id}" max="${
    product.construction.remaining_months_till_deliver
  }" min="0" name="quotated_products_attributes[][installments]" step="1" type="number" value="${
    product.construction.remaining_months_till_deliver
  }" />
            </div>
          </div>
        </div>
      </div>
    </div>
  `;
};

const productInstallmentsMassiveInputs = (product) => {
  return `
    <div class="row">
      <div class="col s12 m4">
        <label for="per-installment-amount-product-${product.id}">Monto por cuota</label>
        <input class="per-installment-amount-changer-${product.id}" type="number" min="0" step="0.01" id="per-installment-amount-product-${product.id}" value="0.00" data-product="${product.id}" />
      </div>
      <div class="col s12 m4">
        <label for="installment-from-month-product-${product.id}">Desde la cuota</label>
        <input class="installment-from-month-changer-${product.id}" type="number" min="1" max="${product.construction.remaining_months_till_deliver}" step="1" id="installment-from-month-product-${product.id}" value="1" data-product="${product.id}" />
      </div>
      <div class="col s12 m4">
        <label for="installment-till-month-product-${product.id}">Hasta la cuota</label>
        <input class="installment-till-month-changer-${product.id}" type="number" min="1" max="${product.construction.remaining_months_till_deliver}" step="1" id="installment-till-month-product-${product.id}" value="1" data-product="${product.id}" />
      </div>
    </div>
  `;
};

const productDatesMassiveInputs = (product) => {
  var thisDate = inputDate(new Date());
  return `
    <div class="row">
      <div class="col s12">
        <label for="start-date-product-${product.id}">Fecha inicial</label>
        <input class="date-distribution-changer-${product.id}" type="date" id="start-date-product-${product.id}" value="${thisDate}" data-product="${product.id}" />
      </div>
    </div>
  `;
};

const productInstallmentVNAPercentChanger = (product) => {
  return `
    <div class="row">
      <div class="col s12 m4">
      </div>
      <div class="col s12 m4">
      <label for="start-date-product-${
        product.id
      }">Distribución cuotas (%)</label>
        <input class="installments-percent-distribution-changer-${
          product.id
        }" type="text" id="installments-percent-distribution-changer-${
    product.id
  }" value="${product.construction.project.minimum_foot_percent}-${
    100 - parseInt(product.construction.project.minimum_foot_percent)
  }" data-product="${product.id}" />
      </div>
      <div class="col s12 m4">
      </div>
    </div>
  `;
};

const installmentsTableContent = (product) => {
  return `
    <div class="row">
      <div class="col s12">
        <div class="col s12">
          ${productNVP(product)}
        </div>
        <div class="col s12">
          <div class="row">
            <div class="col s12 m4 bar-shadow-right">
              <div class="p-10">
                ${productDatesMassiveInputs(product)}
              </div>
            </div>
            <div class="col s12 m8">
              <div class="p-10">
                ${productInstallmentsMassiveInputs(product)}
                ${productInstallmentVNAPercentChanger(product)}
              </div>
            </div>
          </div>
        </div>
        <div class="col s12 product-payment-installments-container-${
          product.id
        } hide">
          <table class="striped" id="product-calc-table-${product.id}">
            <thead>
              <tr>
                <th>N°</th>
                <th>Fecha</th>
                <th>Monto</th>
              </tr>
            </thead>
            <tbody id="product-calc-body-${product.id}"></tbody>
          </table>
        </div>
      </div>
    </div>
  `;
};

const floatingNVPnumbers = (product) => {
  return `
    <div class="footer-fixed nvp-footer">
      <footer class="white">
        ${productNVP2(product)}
      </footer>
    </div>
  `;
};

export const reserveRowContent = (number, date, amount, productId) => {
  date = inputDate(date);
  return `
    <tr class="product-reserve-row-${productId} table-row-separation" data-month="${number}">
      <td>Seña</td>
      <td><input class="data-reserve-month-for-${productId}" type="date" name="quotated_products_attributes[][first_reserve_payment_date]" value="${date}" data-month="${number}" /></td>
      <td><input class="product-${productId}-reserve-amount product-${productId}-reserve-amount-${number} product-reserve-amount" type="number" name="quotated_products_attributes[][first_reserve_payment_amount]" data-product="${productId}" data-month="${number}" min="0" step="0.01" value="${amount.toFixed(
    2
  )}" /></td>
    </tr>
  `;
};

export const installmentRowContent = (number, date, amount, productId) => {
  date = inputDate(date);
  return `
    <tr class="product-installment-row-${productId}" data-month="${number}">
      <td><input class="no-border hide" type="number" readonly name="quotated_products_attributes[][payment_details[][number]]" value="${number}" />${number}</td>
      <td><input class="data-month-date-for-${productId}" type="date" name="quotated_products_attributes[][payment_details[][date]]" value="${date}" data-month="${number}" /></td>
      <td><input class="product-${productId}-payment-month product-${productId}-payment-month-${number} product-payment-months" type="number" name="quotated_products_attributes[][payment_details[][amount]]" data-product="${productId}" data-month="${number}" min="0" step="0.01" value="${amount.toFixed(
    2
  )}" /></td>
    </tr>
  `;
};

export const productPaymentPlanForm = (product) => {
  product = product ? product : {};
  return `
  <li class="product${product.id}">
    <div class="collapsible-header">
      <i class="material-icons">chevron_right</i>
      ${product.category.translation} ${product.concatenate_name}
    </div>
    <div class="collapsible-body">
      ${productPaymentContent(product)}
    </div>
  </li>`;
};

const mortgageContainer = (product) => {
  product = product ? product : {};
  return `
    <div class="row">
    </div>
  `;
};
