// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
// require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("chartkick");
require("chart.js");
require("trix");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// ============================
// External Libraries
// ============================
// require("packs/lib/jquery-3.2.1.min.js");
// require("packs/lib/materialize.min.js");
// require("packs/lib/owl.carousel.min.js");
// require("packs/lib/aos.min.js");
// require("packs/lib/scrollit.js");
// require("packs/lib/jquery.counterup.min.js");
// require("packs/lib/animated/EasePack.min.js");
// require("packs/lib/animated/TweenLite.min.js");
// require("packs/lib/jquery.matchHeight-min.js");
// require("packs/lib/lity.min.js");
// require("packs/lib/spectragram.min.js");
// require("packs/lib/contact-form.js");
// require("packs/lib/maps.js");
// require("packs/lib/main.js");
import "actiontext";
import "../stylesheets/application";
import "materialize-css/dist/js/materialize";
// import "materialize-tags/src/materialize-tags";

import { $_ } from "@rails/ujs";

// ============================
// Our Libs
// ============================
// import { toggleLoader } from "packs/lib/loader";
// import { serverRequest } from "packs/lib/callbacable";
// ============================
// Our Configs
// ============================
require("packs/config/init");
require("packs/config/actiontext_customs");
require("packs/config/notifications");
// ============================
// Our custom JS
// ============================
require("packs/quotations");
require("packs/reserves");
require("packs/promises");
require("packs/deeds");

document.addEventListener("DOMContentLoaded", function () {
  $(".collapsible-header").on("click", function (event) {
    var currentClasses = this.classList.value;
    var iconChildren = $($(this).children()[0]);
    if (iconChildren && iconChildren.text()) {
      if (currentClasses.includes("active")) {
        iconChildren.text("chevron_right");
      } else {
        iconChildren.text("expand_more");
      }
    }
  });

  const snakeCase = (string) => {
    return string
      .replace(/\W+/g, " ")
      .split(/ |\B(?=[A-Z])/)
      .map((word) => word.toLowerCase())
      .join("_");
  };
  var colorChanger = document.getElementsByName("site_config[base_color]")[0];

  $(colorChanger).on("change", function (event) {
    var colorHolder = document.getElementById("color-holder");
    var selectedOpt = null;
    $(colorChanger)
      .children()
      .each(function (_, el) {
        if ($(el).val() === $(colorChanger).val()) {
          selectedOpt = el;
        }
      });
    if (selectedOpt && colorHolder) {
      var colorText = $(selectedOpt).text().split(" ")[1];
      console.log(colorText);

      $(colorHolder)
        .empty()
        .prepend(
          `<div><div class="left">Primary: </div><div class='color-box color-box-style-${colorText}-1'></div><div class="left"> Secondary: </div><div class='color-box color-box-style-${colorText}-2'></div><div class="left"> Text: </div><div class='color-box color-box-style-${colorText}-3'></div></div>`
        );
    }
  });

  var event = new Event("change");
  if (colorChanger) {
    colorChanger.dispatchEvent(event);
  }

  setTimeout(function () {
    var fontsDropdownContent = $(
      ".fonts-field .select-wrapper .dropdown-content"
    )[0];
    if (fontsDropdownContent) {
      $(fontsDropdownContent)
        .children()
        .each(function (_, li) {
          var liText = $(li).text();
          var snakeText = snakeCase(liText);
          $(li).addClass(`font-style-${snakeText}`);
        });
    }
  }, 500);

  function openNav() {
    if (document.getElementById("btn-display-menu")) {
      $("div#sidenav-left.sidenav").width(280);
      document.getElementById("btn-display-menu").style.left = "258px";
      var userContainer = document.getElementsByClassName("users-container")[0];
      if (userContainer) {
        userContainer.style.maxWidth = "calc(100% - 320px)";
        var mainCont = document.getElementById("main-cont");
        if (mainCont) {
          mainCont.style.marginLeft = "300px";
        }
      }
    }
  }

  /* Set the width of the sidebar to 0 and the left margin of the page content to 0 */
  function closeNav() {
    $("div#sidenav-left.sidenav").width(0);
    var btn = document.getElementById("btn-display-menu");
    if (btn) {
      document.getElementById("btn-display-menu").style.left = "-7px";
      var userContainer = document.getElementsByClassName("users-container")[0];
      if (userContainer) {
        document.getElementsByClassName("users-container")[0].style.maxWidth =
          "calc(100% - 20px)";
      }
      var mainCont = document.getElementById("main-cont");
      if (mainCont) {
        document.getElementById("main-cont").style.marginLeft = "20px";
      }
    }
  }

  const checkNav = (showIt) => {
    if (showIt) {
      // open
      openNav();
      navOpen = "true";
      localStorage.setItem("navOpen", navOpen);
    } else {
      // close
      closeNav();
      navOpen = "false";
      localStorage.setItem("navOpen", navOpen);
    }
  };
  $("#btn-display-menu").on("click", (event) => {
    let navOpen = localStorage.getItem("navOpen");
    let hasToShowIt = !(navOpen === "true");
    checkNav(hasToShowIt);
  });

  let navOpen = localStorage.getItem("navOpen");
  checkNav(navOpen === "true");

  $(".delete-button").on("mousedown", (event, options) => {
    if (options == undefined) {
      event.preventDefault();

      mzbox.confirm({
        title: "Confirmación",
        message: "¿Estas seguro de querer eliminar el registro?",
        callback: function (res) {
          if (res == true) {
            $(event.target)[0].click();
          }
        },
        locale: "es",
      });
    }
  });

  $(".show_password").on("click", function () {
    $("input[type=password]").addClass("password_showed");
    $("input[type=password]").attr("type", "text");
    $(".show_password").addClass("hide");
    $(".hide_password").removeClass("hide");
  });

  $(".hide_password").on("click", function () {
    $(".password_showed").attr("type", "password");
    $(".password_showed").removeClass("password_showed");
    $(".hide_password").addClass("hide");
    $(".show_password").removeClass("hide");
  });

  setTimeout(() => {
    $(".select2-selection.select2-selection--multiple")
      .parent()
      .parent()
      .css("border", "0px")
      .css("height", "50px");
  }, 500);
});
